import axiosHelper from '../helper/axiosHelper';
import * as env from '../../env';

const serverUrl = env.httpProtocol.concat(
  env.serverHost,
  ':',
  env.serverPort,
  '/organization',
);

const paramHeaders = {
  headers: {
    Accept: 'application/json',
  },
  withCredentials: true,
};
export const getAllOrganization = async () => {
  const url = serverUrl;
  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};
export const getOrganization = async (idOrg) => {
  const url = serverUrl.concat('/', idOrg);
  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

/* export const saveUser = async (data) => {
  const url = serverUrl;
  const result = await axiosHelper.axiosPut(url, data, paramHeaders);
  return result;
}; */

export const AddOrganizationUsers = async (data) => {
  const url = serverUrl;
  const result = await axiosHelper.axiosPut(url, data, paramHeaders);
  return result;
};

/* export const updateUser = async (data) => {
  const url = serverUrl;
  const result = await axiosHelper.axiosPatch(url, data, paramHeaders);
  return result;
}; */

/* export const deleteUser = async (data) => {
  const url = serverUrl;
  const result = await axiosHelper.axiosDelete(url, data, paramHeaders);
  return result;
};
 */
