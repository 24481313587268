import moment from "moment";
import { generateID } from "./common";

const currentDateTime = moment().unix()*1000;

const DemoFormLayouts: any = [
  {
    id: generateID(),
    formName: "Regular library form",
    createdAt: currentDateTime,
    creator: "Test User",
    formLayoutComponents: [
      {
        container: {
          id: "xdb2ni4u0md3nflqc1qksy",
          controlName: "step-container",
          displayText: "Workflow Step",
          itemType: "container",
          icon: "fa fa-building",
          heading: "Library Card",
          subHeading: "",
        },
        children: [
          {
            id: 'dl0dduqw8s991yf8amgg5',
            controlName: 'text-field',
            displayText: "Text Field",
            placeholder: "Placeholder for Text Field",
            description: "Some Description about the field",
            labelName: "Card number",
            itemType: 'control',
            dataType: 'text',
            icon: "fas fa-text-height",
            required: false,
            category: "text-elements",
            containerId: '',
          },
          {
            id: "h3qfythhwh5kbi9l5s0hrn",
            controlName: "image-upload",
            displayText: "Image",
            description: "",
            labelName: "Upload Image for POD",
            itemType: "control",
            icon: "far fa-image",
            required: true,
            category: "media-elements",
            containerId: "nyqlps3erjkgfng1rxgbvn",
          },
          {
            id: 'dl0dduqw8s991yf8amgg5',
            controlName: 'text-field',
            displayText: "Text Field",
            placeholder: "Placeholder for Text Field",
            description: "Some Description about the field",
            labelName: "Name",
            itemType: 'control',
            dataType: 'text',
            icon: "fas fa-text-height",
            required: false,
            category: "text-elements",
            containerId: '',
          },
          {
            id: 'dl0dduqw8s991yf8ayd4mgg5',
            controlName: 'text-field',
            displayText: "Text Field",
            placeholder: "Placeholder for Text Field",
            description: "Some Description about the field",
            labelName: "Address",
            itemType: 'control',
            dataType: 'text',
            icon: "fas fa-text-height",
            required: false,
            category: "text-elements",
            containerId: '',
          },
          {
            id: '45y56yu56uy57u7u',
            controlName: 'date-field',
            displayText: "Date Picker",
            description: "Some Description about the field",
            labelName: "Date of birth",
            itemType: 'control',
            icon: "far fa-calendar",
            required: false,
            category: "date-elements",
            containerId: '',
          },
          {
            id: '67u67u7u5u767u67u67u67u',
            controlName: 'date-field',
            displayText: "Date Picker",
            description: "Some Description about the field",
            labelName: "Issue date",
            itemType: 'control',
            icon: "far fa-calendar",
            required: false,
            category: "date-elements",
            containerId: '',
          },
          {
            id: '67u67u7u5gfgjj767u67u67u67u',
            controlName: 'date-field',
            displayText: "Date Picker",
            description: "Some Description about the field",
            labelName: "Validate date",
            itemType: 'control',
            icon: "far fa-calendar",
            required: false,
            category: "date-elements",
            containerId: '',
          },
          {
            id: "dl0dduqw8s991yf8amgsm",
            controlName: "radio-group",
            displayText: "Radio",
            description: "Enter the dropoff location of the package",
            labelName: "Dropoff Location",
            itemType: "control",
            icon: "far fa-dot-circle",
            required: true,
            items: [
              {
                id: "56azek5q7i2m97yfbmw7j",
                value: "Front__-Door",
                label: "Front Door",
              },
              {
                id: "y1d6eq3231v2wsvs1ocy",
                value: "Back__-Door",
                label: "Back Door",
              },
              {
                id: "990tgqa51essjkas0gqzgs",
                value: "Package__-Locker",
                label: "Package Locker",
              },
            ],
            category: "other-elements",
            containerId: "xdb2ni4u0md3nflqc1qksy",
          },
        ],
      },
      {
        container: {
          id: "nyqlps3erjkgfng1rxgbvn",
          controlName: "step-container",
          displayText: "Workflow Step",
          itemType: "container",
          icon: "fa fa-building",
          heading: "Container Heading",
          subHeading: "Container SubHeading",
        },
        children: [
          {
            id: "mru48gcfqzxb34ifrdwpf",
            controlName: "signature",
            displayText: "Signature",
            description: "",
            labelName: "Customer Signature",
            itemType: "control",
            icon: "fa fa-signature",
            required: true,
            category: "other-elements",
            containerId: "xdb2ni4u0md3nflqc1qksy",
          },
        ],
      },
    ],
    lastPublishedAt: 0,
    publishHistory: [],
    publishStatus: "draft",
    updatedAt: currentDateTime,
  },
];

export default DemoFormLayouts;