const httpProtocol = process.env.GATSBY_HTTP_PROTOCOL || `http://`;
const clientHost = process.env.GATSBY_CLIENT_HOST || 'localhost';
const serverHost = process.env.GATSBY_SERVER_HOST || 'localhost';
const serverPort = process.env.GATSBY_SERVER_PORT || '5000';
const serverAuth = process.env.GATSBY_SERVER_LOGIN_ROUTE || '/auth/resource';
const serverCookieName = process.env.GATSBY_SERVER_COOKIE_NAME || 'SINEUUI';
const loginUrl = `${httpProtocol}${serverHost}:${serverPort}${serverAuth}`;
const logoutUrl = `${httpProtocol}${serverHost}:${serverPort}${process.env.GATSBY_SERVER_LOGOUT_ROUTE}`;
const nodeEnv = process.env.GATSBY_NODE_ENV;
const websocketUrl = process.env.GATSBY_WEBSOCKET_URL;

export {
  httpProtocol,
  clientHost,
  serverHost,
  serverPort,
  serverAuth,
  serverCookieName,
  loginUrl,
  logoutUrl,
  nodeEnv,
  websocketUrl,
};
