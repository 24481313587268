import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './message';
import { getAllOrganization } from '../service/organization';
export const setOrganization = createAsyncThunk(
  'path/organization',
  async (data) => {
    console.log('slice set organization: ', data);
    return {
      organization: data,
    };
  },
);
export const getOrganizationAPI = createAsyncThunk(
  'get/organization',
  async (thunkAPI) => {
    try {
      const responseOrganization = await getAllOrganization();
      let organizationData;
      if (responseOrganization && responseOrganization.data)
        organizationData = responseOrganization.data;
      return {
        organization: organizationData,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(
        setMessage({
          message: message,
          severity: 'error',
        }),
      );
      return thunkAPI.rejectWithValue();
    }
  },
);
const initialState = {};
const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganizationAPI.fulfilled, (state, action) => {
      state.organization = action.payload.organization;
    });
  },
  /* extraReducers: {
    [getOrganizationAPI.fulfilled]: (state, action) => {
      state.organization = action.payload.organization;
    },
    [getOrganizationAPI.rejected]: (state, action) => {
      state.organization = null;
    },
    [setOrganization.fulfilled]: (state, action) => {
      state.organization = action.payload.organization;
    },
    [setOrganization.rejected]: (state, action) => {
      state.organization = null;
    }
  } */
});
const { reducer } = organizationSlice;
export default reducer;
