import axios from 'axios';
const navigate = require('gatsby').navigate;
// intercepting responses
axios.interceptors.response.use(
  function (response: any) {
    // Do something with response data
    return response;
  },
  function (error: any) {
    // Do something with response error
    // redirect to login
    /* if (error.response.status === 401) {
      navigate('/dashboard/login');
    } */
    // redirect to ORDERS list
   /*  if (error.response.status === 402) {
      navigate('/dashboard/subscriptions', {
        state: { msg: error.response.data },
      });
    } */
    // redirect to subscription plan
   /*  if (error.response.status === 432) {
      navigate('/dashboard/subscribe', {
        state: { msg: error.response.data },
      });
    } */
    // new namespace required
   /*  if (error.response.status === 453) {
      navigate('/dashboard/settings');
    } */
    return Promise.reject(error);
  },
);

const axiosHelper = {
  async axiosGet(url: any, HeaderOptions: any) {
    return await axios
      .get(url, HeaderOptions)
      .then((response: any) => {
        return response;
      })
      .catch((error: { response: any }) => {
        return error.response;
      });
  },
  async axiosPost(url: any, bodyOption: any, HeaderOptions: any) {
    return await axios
      .post(url, bodyOption, HeaderOptions)
      .then((response: any) => {
        return response;
      })
      .catch((error: { response: any }) => {
        return error.response;
      });
  },

  async axiosPut(url: any, bodyOption: any, HeaderOptions: any) {
    return await axios
      .put(url, bodyOption, HeaderOptions)
      .then((response: any) => {
        return response;
      })
      .catch((error: { response: any }) => {
        return error.response;
      });
  },

  async axiosPatch(url: any, bodyOption: any, HeaderOptions: any) {
    return await axios
      .patch(url, bodyOption, HeaderOptions)
      .then((response: any) => {
        return response;
      })
      .catch((error: { response: any }) => {
        return error.response;
      });
  },

  async axiosDelete(url: any, bodyOption: any) {
    return await axios
      .delete(url, bodyOption)
      .then((response: { data: any }) => {
        return response.data;
      })
      .catch((error: { response: any }) => {
        return error.response;
      });
  },
};

export default axiosHelper;
