exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-credicaixa-tsx": () => import("./../../../src/pages/admin/credicaixa.tsx" /* webpackChunkName: "component---src-pages-admin-credicaixa-tsx" */),
  "component---src-pages-admin-geral-tsx": () => import("./../../../src/pages/admin/geral.tsx" /* webpackChunkName: "component---src-pages-admin-geral-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-list-model-tsx": () => import("./../../../src/pages/admin/list-model.tsx" /* webpackChunkName: "component---src-pages-admin-list-model-tsx" */),
  "component---src-pages-admin-list-user-mui-tsx": () => import("./../../../src/pages/admin/list-user-mui.tsx" /* webpackChunkName: "component---src-pages-admin-list-user-mui-tsx" */),
  "component---src-pages-admin-list-user-tsx": () => import("./../../../src/pages/admin/list-user.tsx" /* webpackChunkName: "component---src-pages-admin-list-user-tsx" */),
  "component---src-pages-admin-user-tsx": () => import("./../../../src/pages/admin/user.tsx" /* webpackChunkName: "component---src-pages-admin-user-tsx" */),
  "component---src-pages-admin-utilizadores-tsx": () => import("./../../../src/pages/admin/utilizadores.tsx" /* webpackChunkName: "component---src-pages-admin-utilizadores-tsx" */),
  "component---src-pages-admin-white-label-tsx": () => import("./../../../src/pages/admin/white-label.tsx" /* webpackChunkName: "component---src-pages-admin-white-label-tsx" */),
  "component---src-pages-confirmacao-tsx": () => import("./../../../src/pages/confirmacao.tsx" /* webpackChunkName: "component---src-pages-confirmacao-tsx" */),
  "component---src-pages-editor-tsx": () => import("./../../../src/pages/editor.tsx" /* webpackChunkName: "component---src-pages-editor-tsx" */),
  "component---src-pages-form-builder-tsx": () => import("./../../../src/pages/form-builder.tsx" /* webpackChunkName: "component---src-pages-form-builder-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-manage-[id]-forms-index-tsx": () => import("./../../../src/pages/manage/[id]/forms/index.tsx" /* webpackChunkName: "component---src-pages-manage-[id]-forms-index-tsx" */),
  "component---src-pages-manage-[id]-model-index-tsx": () => import("./../../../src/pages/manage/[id]/model/index.tsx" /* webpackChunkName: "component---src-pages-manage-[id]-model-index-tsx" */),
  "component---src-pages-manage-[id]-proposal-index-tsx": () => import("./../../../src/pages/manage/[id]/proposal/index.tsx" /* webpackChunkName: "component---src-pages-manage-[id]-proposal-index-tsx" */),
  "component---src-pages-manage-[id]-settings-index-tsx": () => import("./../../../src/pages/manage/[id]/settings/index.tsx" /* webpackChunkName: "component---src-pages-manage-[id]-settings-index-tsx" */),
  "component---src-pages-manage-[id]-subscription-index-tsx": () => import("./../../../src/pages/manage/[id]/subscription/index.tsx" /* webpackChunkName: "component---src-pages-manage-[id]-subscription-index-tsx" */),
  "component---src-pages-manage-[id]-tsx": () => import("./../../../src/pages/manage/[id].tsx" /* webpackChunkName: "component---src-pages-manage-[id]-tsx" */),
  "component---src-pages-manage-index-tsx": () => import("./../../../src/pages/manage/index.tsx" /* webpackChunkName: "component---src-pages-manage-index-tsx" */),
  "component---src-pages-manage-login-tsx": () => import("./../../../src/pages/manage/login.tsx" /* webpackChunkName: "component---src-pages-manage-login-tsx" */),
  "component---src-pages-propor-tsx": () => import("./../../../src/pages/propor.tsx" /* webpackChunkName: "component---src-pages-propor-tsx" */),
  "component---src-pages-rever-tsx": () => import("./../../../src/pages/rever.tsx" /* webpackChunkName: "component---src-pages-rever-tsx" */),
  "component---src-pages-templates-tsx": () => import("./../../../src/pages/templates.tsx" /* webpackChunkName: "component---src-pages-templates-tsx" */)
}

