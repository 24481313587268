import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../src/slices/user';
import messageReducer from '../src/slices/message';
import organizationReducer from '../src/slices/organization';
import entitiesReducer from '../src/slices/entities'
import uireducers from '../src/slices/uireducers'

const reducer = {
  user: userReducer,
  message: messageReducer,
  organization: organizationReducer,
  entities: entitiesReducer,
  uielements: uireducers
};

const store = configureStore({
  reducer: reducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
