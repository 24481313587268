import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './message';
import { getUser, updateUser } from '../service/user';
export const patchUser = createAsyncThunk(
  'path/user',
  async (data, thunkAPI) => {
    try {
      const response = await updateUser(data);
      if (response.status === 200) {
        thunkAPI.dispatch(
          setMessage({
            message: 'Records added successfully!',
            severity: 'info',
          }),
        );
        return {
          user: response.data,
        };
      } else {
        thunkAPI.dispatch(
          setMessage({
            message: response.data,
            severity: 'error',
          }),
        );
        throw response.data;
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(
        setMessage({
          message: message,
          severity: 'error',
        }),
      );
      return thunkAPI.rejectWithValue();
    }
  },
);
export const getUserAPI = createAsyncThunk('get/user', async (thunkAPI) => {
  try {
    const responseUser = await getUser();
    let userData;
    if (responseUser && responseUser.data) userData = responseUser.data;
    return {
      user: userData,
    };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(
      setMessage({
        message: message,
        severity: 'error',
      }),
    );
    return thunkAPI.rejectWithValue();
  }
});
const initialState = {};
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserAPI.fulfilled, (state, action) => {
      state.user = action.payload.user;
    });
  },
  /* extraReducers: {
    [getUserAPI.fulfilled]: (state, action) => {
      state.user = action.payload.user;
    },
    [getUserAPI.rejected]: (state, action) => {
      state.user = null;
    },
    [patchUser.fulfilled]: (state, action) => {
      state.user = action.payload.user;
    },
    [patchUser.rejected]: (state, action) => {
      state.user = null;
    }
  } */
});
const { reducer } = userSlice;
export default reducer;
